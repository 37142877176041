import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { IoMdCheckmark } from 'react-icons/io';
import { MdCookie, MdDescription } from 'react-icons/md';

const LegalPages = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('terms');
  const [cookiePreferences, setCookiePreferences] = useState({
    necessary: true,
    functional: true,
    analytics: true,
    marketing: false
  });

  useEffect(() => {
    const path = location.pathname.split('/').pop();
    if (path === 'cookie-policy') setActiveTab('cookies');
    else setActiveTab('terms');

    // Scroll to top on page load
    window.scrollTo(0, 0);
  }, [location]);

  const handleCookiePreferenceChange = (key) => {
    if (key === 'necessary') return; // Necessary cookies can't be disabled
    setCookiePreferences(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  const tabVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { 
      opacity: 1, 
      x: 0,
      transition: { duration: 0.3 }
    }
  };

  const renderTermsAndConditions = () => (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold mb-4">Terms and Conditions</h2>
      
      <section className="space-y-4">
        <h3 className="text-xl font-semibold">1. Company Information</h3>
        <div className="bg-gray-50 p-4 rounded-lg">
          <p className="font-bold mb-2">DIAA Corp Private Limited</p>
          <p>CIN: U72900TN2025PTC123456</p>
          <p>GSTIN: 33AABCD1234A1Z5</p>
          <p>Registered Office: 123 Business Avenue</p>
          <p>Tech District</p>
          <p>Dubai, UAE</p>
          <p>Email: legal@diaacorp.com</p>
          <p>Phone: +91 44 1234 5678</p>
        </div>
      </section>

      <section className="space-y-4">
        <h3 className="text-xl font-semibold">2. Services</h3>
        <p>DIAA Corp Private Limited specializes in:</p>
        <ul className="list-disc pl-6">
          <li>SAP Implementation and Consulting Services</li>
          <li>Application Development and Management</li>
          <li>Cloud Services and Digital Transformation</li>
          <li>Enterprise Solutions and Managed Services</li>
        </ul>
      </section>
      
      <section className="space-y-4">
        <h3 className="text-xl font-semibold">3. Intellectual Property Rights</h3>
        <p>All content, trademarks, and intellectual property on this website are owned by DIAA Corp Private Limited. SAP® and other SAP products mentioned are trademarks or registered trademarks of SAP SE in Germany and other countries.</p>
      </section>
      
      <section className="space-y-4">
        <h3 className="text-xl font-semibold">4. User Responsibilities</h3>
        <p>Users must not misuse our services, infringe upon intellectual property rights, or violate any applicable laws while using our website and services.</p>
      </section>

      <section className="space-y-4">
        <h3 className="text-xl font-semibold">5. Limitation of Liability</h3>
        <p>While DIAA Corp strives to provide accurate information and reliable services, we make no warranties about the completeness, reliability, and accuracy of this information. Any action you take upon the information on this website is strictly at your own risk.</p>
      </section>
      
      {/* Add more sections as needed */}
    </div>
  );

  const renderCookiePolicy = () => (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold mb-4">Cookie Policy</h2>
      
      <div className="space-y-4">
        <div className="bg-white p-6 rounded-lg">
          <h3 className="text-xl font-semibold mb-4">Cookie Preferences</h3>
          
          {Object.entries(cookiePreferences).map(([key, value]) => (
            <div key={key} className="flex items-center justify-between py-3 border-b border-gray-700">
              <div>
                <h4 className="font-semibold capitalize">{key} Cookies</h4>
                <p className="text-sm text-gray-700">
                  {key === 'necessary' 
                    ? 'Required for the website to function properly'
                    : `Used for ${key} purposes and features`}
                </p>
              </div>
              <button
                onClick={() => handleCookiePreferenceChange(key)}
                disabled={key === 'necessary'}
                className={`w-12 h-6 rounded-full transition-colors duration-200 ease-in-out relative ${
                  value ? 'bg-purple-600' : 'bg-gray-600'
                } ${key === 'necessary' ? 'cursor-not-allowed' : 'cursor-pointer'}`}
              >
                <span
                  className={`absolute top-1 left-1 w-4 h-4 bg-white rounded-full transition-transform duration-200 ease-in-out ${
                    value ? 'transform translate-x-6' : ''
                  }`}
                />
              </button>
            </div>
          ))}
        </div>
      </div>
      
      <section className="space-y-4">
        <h3 className="text-xl font-semibold">About Our Cookie Policy</h3>
        <p>This Cookie Policy explains how DIAA Corp Private Limited uses cookies and similar technologies to provide, customize, evaluate, improve, and secure our services.</p>
      </section>

      <section className="space-y-4">
        <h3 className="text-xl font-semibold">Types of Cookies We Use</h3>
        <ul className="list-disc pl-6 space-y-2">
          <li><strong>Essential Cookies:</strong> Required for website functionality and security.</li>
          <li><strong>Analytics Cookies:</strong> Help us understand user behavior and improve our services.</li>
          <li><strong>Functional Cookies:</strong> Remember your preferences and provide enhanced features.</li>
          <li><strong>SAP-Related Cookies:</strong> Required for certain SAP service functionalities and analytics.</li>
        </ul>
      </section>

      <section className="space-y-4">
        <h3 className="text-xl font-semibold">Data Protection</h3>
        <p>For questions about our cookie practices or data protection:</p>
        <div className="bg-gray-50 p-4 rounded-lg mt-2">
          <p>Data Protection Officer</p>
          <p>DIAA Corp Private Limited</p>
          <p>Email: privacy@diaacorp.com</p>
          <p>Phone: +91 44 1234 5678</p>
        </div>
      </section>
    </div>
  );

  return (
    <motion.div 
      className="min-h-screen py-20 px-4"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <div className="max-w-4xl mx-auto">
        {/* Tab Navigation */}
        <div className="flex flex-wrap gap-4 mb-8">
          {[
            { id: 'terms', icon: <MdDescription />, label: 'Terms & Conditions' },
            { id: 'cookies', icon: <MdCookie />, label: 'Cookie Policy' }
          ].map(tab => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`flex items-center space-x-2 px-6 py-3 rounded-lg transition-colors duration-200 ${
                activeTab === tab.id 
                  ? 'bg-purple-600 text-white' 
                  : 'bg-white hover:bg-gray-100'
              }`}
            >
              {tab.icon}
              <span>{tab.label}</span>
            </button>
          ))}
        </div>

        {/* Content Area */}
        <motion.div
          key={activeTab}
          initial="hidden"
          animate="visible"
          variants={tabVariants}
          className="bg-white p-8 rounded-xl"
        >
          {activeTab === 'terms' && renderTermsAndConditions()}
          {activeTab === 'cookies' && renderCookiePolicy()}
        </motion.div>
      </div>
    </motion.div>
  );
};

export default LegalPages;
