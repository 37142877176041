import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import DIAA_NAME from '../assets/DIAA_Trademark.png';

const API_URL = 'https://diaacorp-backend.ew.r.appspot.com/api/email/send';
// const API_URL = 'http://localhost:5000/api/email/send';

const Footer = () => {
  const location = useLocation();
  const isRiseWithSapRoute = location.pathname === '/rise-with-sap';
  const isSap4hana = location.pathname === '/sap-s4hana';
  const Home = location.pathname === '/';

  const [isFormFocused, setIsFormFocused] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
  });
  const [status, setStatus] = useState({
    message: '',
    type: '' // 'success' or 'error'
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    mobile: '',
  });
  const [hasSubmitAttempt, setHasSubmitAttempt] = useState(false);

  useEffect(() => {
    const title = document?.title || 'Home';
    
  }, [location]);

  const handleFormFocus = () => {
    setIsFormFocused(true);
  };

  const handleFormBlur = () => {
    setIsFormFocused(false);
  };

  const validateInput = (name, value, isSubmitting = false) => {
    if (!hasSubmitAttempt && !isSubmitting) return '';

    switch (name) {
      case 'name':
        if (!value.trim()) return 'Name is required';
        if (value.length > 50) return 'Name must be less than 50 characters';
        if (!/^[A-Za-z\s]+$/.test(value)) return 'Name can only contain letters and spaces';
        return '';
      
      case 'email':
        if (!value.trim()) return 'Email is required';
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) return 'Invalid email format';
        if (value.length > 100) return 'Email must be less than 100 characters';
        return '';
      
      case 'mobile':
        if (!value.trim()) return 'Mobile number is required';
        if (!/^\d+$/.test(value)) return 'Mobile number can only contain digits';
        if (value.length < 8 || value.length > 15) return 'Mobile number must be between 8 and 15 digits';
        return '';
      
      default:
        return '';
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    
    const error = validateInput(name, value, false);
    setErrors(prev => ({
      ...prev,
      [name]: error
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setHasSubmitAttempt(true);
    
    const newErrors = {
      name: validateInput('name', formData.name, true),
      email: validateInput('email', formData.email, true),
      mobile: validateInput('mobile', formData.mobile, true),
    };
    
    setErrors(newErrors);
    
    if (Object.values(newErrors).some(error => error)) {
      return;
    }
    
    setIsLoading(true);
    setStatus({ message: '', type: '' });

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formData.name,
          email: formData.email,
          mobile: formData.mobile,
          subject: `${document?.title}`,
          message: `
            Name: ${formData.name}
            Email: ${formData.email}
            Page: ${location.pathname}
          `
        })
      });

      const data = await response.json();

      if (data.success) {
        setStatus({
          message: 'Thank you for your enquiry. We will contact you soon!',
          type: 'success'
        });
        // Reset form
        setFormData({ name: '', email: '', mobile: '' });
        // Auto-dismiss status message after 3 seconds
        setTimeout(() => {
          setStatus({ message: '', type: '' });
        }, 3000);
      } else {
        setStatus({
          message: 'Failed to send message. Please try again.',
          type: 'error'
        });
        // Auto-dismiss error message after 3 seconds
        setTimeout(() => {
          setStatus({ message: '', type: '' });
        }, 3000);
      }
    } catch (error) {
      console.error('Error:', error);
      setStatus({
        message: 'An error occurred. Please try again later.',
        type: 'error'
      });
      // Auto-dismiss error message after 3 seconds
      setTimeout(() => {
        setStatus({ message: '', type: '' });
      }, 3000);
    } finally {
      setIsLoading(false);
    }
  };

  const StatusMessage = () => (
    status.message && (
      <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0 }}
        className={`text-sm ${status.type === 'success' ? 'text-green-400' : 'text-red-400'} mt-2`}
      >
        {status.message}
      </motion.div>
    )
  );

  return (
    <footer className="text-white py-8 pt-32">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between">
          {/* First column remains unchanged */}
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h3 className="text-lg font-semibold mb-4">Preference center</h3>
            <ul>
              <li className="mb-2"><a href="/search-jobs-by-areas-of-expertise" className="hover:text-gray-300">Careers</a></li>
              <li className="mb-2"><a href="/about-us" className="hover:text-gray-300">About Us</a></li>
              <li className="mb-2"><a href="/contact-us" className="hover:text-gray-300">Contact Us</a></li>
              <li className="mb-2"><a href="/locations" className="hover:text-gray-300">Location</a></li>
            </ul>
          </div>

          {/* Second column remains unchanged */}
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h3 className="text-lg font-semibold mb-4">Privacy Statement</h3>
            <ul>
              <li className="mb-2">
                <Link to="/terms-and-conditions" className="hover:text-gray-300">
                  Terms and condition
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/cookie-policy" className="hover:text-gray-300">
                  Cookie Policy/Settings
                </Link>
              </li>
              {/* <li className="mb-2">
                <Link to="/accessibility" className="hover:text-gray-300">
                  Accessibility/Settings
                </Link>
              </li> */}
            </ul>
          </div>

          {/* Third column with updated form */}
          {!Home ? (
            <div className="w-full md:w-1/3">
              <motion.h3
                className="text-lg font-semibold mb-4"
                animate={isFormFocused ? {} : { scale: [1, 1.05, 1] }}
                transition={{ duration: 2, repeat: Infinity }}
              >
                {isSap4hana ? 'Service form' : 'Enquire Now'}
              </motion.h3>
              <form onFocus={handleFormFocus} onBlur={handleFormBlur} onSubmit={handleSubmit}>
                <div className="relative">
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="Enter your name"
                    className={`w-full mb-1 p-2 bg-[#1A1F31] rounded ${errors.name ? 'border border-red-500' : ''}`}
                    required
                  />
                  {errors.name && <p className="text-red-500 text-xs mb-2">{errors.name}</p>}
                </div>
                
                <div className="relative">
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="Enter your email"
                    className={`w-full mb-1 p-2 bg-[#1A1F31] rounded ${errors.email ? 'border border-red-500' : ''}`}
                    required
                  />
                  {errors.email && <p className="text-red-500 text-xs mb-2">{errors.email}</p>}
                </div>
                
                <div className="relative">
                  <input
                    type="tel"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleInputChange}
                    placeholder="Enter your mobile number"
                    className={`w-full mb-1 p-2 bg-[#1A1F31] rounded ${errors.mobile ? 'border border-red-500' : ''}`}
                    required
                  />
                  {errors.mobile && <p className="text-red-500 text-xs mb-2">{errors.mobile}</p>}
                </div>
                
                <AnimatePresence>
                  <StatusMessage />
                </AnimatePresence>
                <motion.button
                  type="submit"
                  className="bg-custom-gradient hover:bg-blue-700 text-white font-bold py-2 px-12 rounded-full mt-4 hover:bg-blue-600 transition duration-300 disabled:opacity-50"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  disabled={isLoading}
                >
                  {isLoading ? 'Sending...' : 'Send'}
                </motion.button>
              </form>
            </div>
          ) : (
            <div className="w-full md:w-1/3 flex items-center justify-center md:justify-end">
              <img src={DIAA_NAME} alt="Laptop" className="w-4/5" />
            </div>
          )}
        </div>
        <div className="mt-8 text-center text-sm">
          <p className='text-gray-400'>&copy; {new Date().getFullYear()} DIAA Corp. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;