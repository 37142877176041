import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import BrowserRouter
import Home from './Home';
import Navbar from '../components/navbar/Navbar';
import RiseWithSap from './RiseWithSap';
import ComingSoon from './ComingSoon';
import SapS4hana from './SapS4hana';
import SapAriba from './SapAriba';
import SapBusinessBydesign from './SapBusinessBydesign';
import SapCx from './SapCx';
import SapSuccessFactors from './SapSuccessFactors';
import SAPMigrationFactory from './SAPMigrationFactory';
import CloudManagedServices from './CloudManagedServices';
import EnterpriseApplications from './EnterpriseApplications';
import SapConsulting from './SapConsulting';
import SapManagedServices from './SapManagedServices';
import ApplicationDevelopmentPage from './ApplicationDev';
import SAPArchitecturalConsulting from './SAPArchitecturalConsulting';
import SAPImplementationServices from './SAPImplementationServices';
import SAPApplicationManagementServices from './SAPApplicationManagementServices';
import SAPSolutionManager from './SAPSolutionManager';
import SAPHosting from './SAPHosting';
import SAPAnalyticsCloud from './SAPAnalyticsCloud';
import CloudServices from './Capabilities/CloudServices';


import CommunicationsAndMedia from './Industries/CommunicationsAndMedia';
import DataAndArtificialIntelligence from './Capabilities/DataAndArtificialIntelligence';
import EnterprisePlatforms from './Capabilities/EnterprisePlatforms';
import Learning from './Capabilities/Learning';
import Metaverse from './Capabilities/Metaverse';
import SalesAndCommerce from './Capabilities/SalesAndCommerce';
import StrategicManagedServices from './Capabilities/StrategicManagedServices';
import TechnologyTransformation from './Capabilities/TechnologyTransformation';
import Energy from './Industries/Energy';
import WeThink from './WeThink';
import Health from './Industries/Health';
import HighTech from './Industries/HighTech';
import Industrial from './Industries/Industrial';
import PublicService from './Industries/PublicService';
import Retail from './Industries/Retail';
import SoftwareAndPlatforms from './Industries/SoftwareAndPlatforms';
import Travel from './Industries/Travel';
import Utilities from './Industries/Utilities';
import Leaders from './We are/Leaders';
import Locations from './We are/Locations';
import AboutUs from './We are/AboutUs';
import SearchAllJobs from './WeHire/SearchAllJobs';
import ExperiencedProfessionals from './WeHire/ExperiencedProfessionals';
import ExecutiveLeaders from './WeHire/ExecutiveLeaders';
import ExploreJobs from './WeHire/ExploreJobs';
import ContactUs from './ContactUs';
import Education from './Industries/Education';
import LegalPages from './LegalPages';
import ManagedServiceAutomation from './ManagedServiceAutomation';
import WorkshopSignup from './WorkshopSignup';
import MediaRelations from './MediaRelations';
import AdminLogin from './AdminLogin';
import AdminDashboard from './AdminDashboard';
import PrivateRoute from '../components/PrivateRoute';

function AppRouter() {
    return (
        <Router> {/* Wrap Routes in Router */}
            <div style={{ paddingTop: '64px' }}>
                <Navbar />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/rise-with-sap" element={<RiseWithSap />} />
                    <Route path="/sap-s4hana" element={<SapS4hana />} />
                    <Route path="/sap-ariba" element={<SapAriba />} />
                    <Route path="/sap-business-by-design" element={<SapBusinessBydesign />} />
                    <Route path="/sap-cx" element={<SapCx />} />
                    <Route path="/sap-successfactors" element={<SapSuccessFactors />} />
                    <Route path="/sap-migration-factory" element={<SAPMigrationFactory />} />
                    <Route path="/cloud-managed-services" element={<CloudManagedServices />} />
                    <Route path="/enterprise-applications-managed-services" element={<EnterpriseApplications />} />
                    <Route path="/sap-consulting" element={<SapConsulting />} />
                    <Route path="/sap-managed-services" element={<SapManagedServices />} />
                    <Route path="/application-development" element={<ApplicationDevelopmentPage />} />
                    <Route path="/sap-architectural-consulting" element={<SAPArchitecturalConsulting />} />
                    <Route path="/sap-implementation-services" element={<SAPImplementationServices />} />
                    <Route path="/sap-application-management-services" element={<SAPApplicationManagementServices />} />
                    <Route path="/sap-solution-manager" element={<SAPSolutionManager />} />
                    <Route path="/sap-hosting" element={<SAPHosting />} />
                    <Route path="/sap-analytics-cloud" element={<SAPAnalyticsCloud />} />
                    <Route path="/cloud" element={<CloudServices />} />
                    <Route path="/application-development" element={<ApplicationDevelopmentPage />} />
                    <Route path="/cloud" element={<CloudServices />} />
                    <Route path="/communications-and-media" element={<CommunicationsAndMedia />} />
                    <Route path="/data-and-artificial-intelligence" element={<DataAndArtificialIntelligence />} />
                    <Route path="/enterprise-platforms" element={<EnterprisePlatforms />} />
                    <Route path="/learning" element={<Learning />} />
                    <Route path="/metaverse" element={<Metaverse />} />
                    <Route path="/sales-and-commerce" element={<SalesAndCommerce />} />
                    <Route path="/strategic-managed-services" element={<StrategicManagedServices />} />
                    <Route path="/technology-transformation" element={<TechnologyTransformation />} />
                    <Route path="/energy" element={<Energy />} />
                    <Route path='/we-think' element={<WeThink/> } />
                    <Route path='/health' element={<Health />} />
                    <Route path='/high-tech' element={<HighTech />} />
                    <Route path='/industrial' element={<Industrial />} />
                    <Route path='/public-service' element={<PublicService />} />
                    <Route path='/retail' element={<Retail />} />
                    <Route path='/software-and-platforms' element={<SoftwareAndPlatforms />} />
                    <Route path='/travel' element={<Travel />} />
                    <Route path='/utilities' element={<Utilities />} />
                    <Route path='/about-us' element={<AboutUs />} />
                    <Route path='/leaders' element={<Leaders />} />
                    <Route path='/locations' element={<Locations />} />
                    <Route path="/search-all-jobs" element={<SearchAllJobs />} />
                    <Route path="/experienced-professionals" element={<ExperiencedProfessionals />} />
                    <Route path="/executive-leaders" element={<ExecutiveLeaders />} />
                    <Route path="/search-jobs-by-areas-of-expertise" element={<ExploreJobs />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                    <Route path="/education" element={<Education />} />
                    <Route path="/terms-and-conditions" element={<LegalPages />} />
                    <Route path="/cookie-policy" element={<LegalPages />} />
                    <Route path="/sap-managed-services-automation" element={<ManagedServiceAutomation />} />
                    <Route path="/workshop-signup" element={<WorkshopSignup />} />
                    <Route path="/media-relations" element={<MediaRelations />} />
                    <Route path="/admin/login" element={<AdminLogin />} />
                    <Route
                        path="/admin/dashboard"
                        element={
                            <PrivateRoute>
                                <AdminDashboard />
                            </PrivateRoute>
                        }
                    />
                    <Route path="*" element={<ComingSoon />} />
                    {/* Add more routes here */}
                </Routes>
            </div>
        </Router>
    );
}

export default AppRouter;
