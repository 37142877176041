import React from 'react';
import { motion } from 'framer-motion';
import { useDocumentTitle } from '../../utils/useDocumentTitle';
import profile_placeholder from '../../assets/profile_placehollder.png'
import Footer from '../../components/Footer';


const AboutUs = () => {
  useDocumentTitle('About Us - DIAA Corp');

  const teamMembers = [
    {
      name: "Sarah Johnson",
      role: "Chief Technology Officer",
      image: profile_placeholder,       
    },
    {
      name: "Michael Chen",
      role: "Head of Innovation",
      image: profile_placeholder
    },
    {
      name: "Emma Williams",
      role: "Solutions Architect",
      image: profile_placeholder
    },
  ];

  return (
    <div className="min-h-screen bg-[#0B101D] text-white">
      {/* Hero Section */}
      <div 
        className="relative h-[60vh] bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: 'url("https://images.unsplash.com/photo-1497366216548-37526070297c?q=80&w=1200&auto=format&fit=crop")',
          backgroundAttachment: 'fixed'
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-60">
          <div className="container mx-auto h-full flex items-center px-4">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="max-w-2xl"
            >
              <h1 className="text-5xl font-bold mb-4">Transforming Business Through Technology</h1>
              <p className="text-xl text-gray-300">Leading the way in digital innovation and enterprise solutions</p>
            </motion.div>
          </div>
        </div>
      </div>

      {/* Mission Section */}
      <section className="py-20 bg-[#1A1F31]">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            className="grid md:grid-cols-2 gap-12 items-center"
          >
            <div>
              <h2 className="text-3xl font-bold mb-6">Our Mission</h2>
              <p className="text-gray-300 mb-4">
                At DIAA Corp, we're committed to delivering innovative technology solutions that empower businesses to achieve their full potential. Our expertise in SAP solutions, cloud services, and digital transformation helps organizations stay ahead in today's competitive landscape.
              </p>
              <p className="text-gray-300">
                We believe in creating lasting partnerships with our clients, understanding their unique challenges, and delivering tailored solutions that drive real business value.
              </p>
            </div>
            <div className="relative h-[400px]">
              <img
                src="https://images.unsplash.com/photo-1553877522-43269d4ea984?q=80&w=600&auto=format&fit=crop"
                alt="Team collaboration"
                className="w-full h-full object-cover rounded-lg shadow-xl"
              />
            </div>
          </motion.div>
        </div>
      </section>

      {/* Values Section */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-12 text-center">Our Core Values</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                title: "Innovation",
                description: "Pushing boundaries and embracing new technologies to solve complex challenges",
                icon: "🚀"
              },
              {
                title: "Excellence",
                description: "Delivering outstanding results through dedication and expertise",
                icon: "⭐"
              },
              {
                title: "Partnership",
                description: "Building strong relationships based on trust and mutual success",
                icon: "🤝"
              }
            ].map((value, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2 }}
                className="bg-[#1A1F31] p-6 rounded-lg"
              >
                <div className="text-4xl mb-4">{value.icon}</div>
                <h3 className="text-xl font-bold mb-3">{value.title}</h3>
                <p className="text-gray-300">{value.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Team Section */}
      <section className="py-20 bg-[#1A1F31]">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-12 text-center">Our Leadership Team</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {teamMembers.map((member, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, scale: 0.9 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ delay: index * 0.2 }}
                className="text-center"
              >
                <div className="mb-4 relative overflow-hidden rounded-lg">
                  <img
                    src={member.image}
                    alt={member.name}
                    className="mx-auto h-64 object-cover"
                  />
                </div>
                <h3 className="text-xl font-bold mb-2">{member.name}</h3>
                <p className="text-gray-300">{member.role}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Stats Section */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-4 gap-8 text-center">
            {[
              { number: "10+", label: "Years Experience" },
              { number: "500+", label: "Projects Completed" },
              { number: "100+", label: "Expert Consultants" },
              { number: "50+", label: "Global Clients" }
            ].map((stat, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ delay: index * 0.2 }}
                className="bg-[#1A1F31] p-6 rounded-lg"
              >
                <div className="text-4xl font-bold mb-2 bg-custom-gradient bg-gradient-to-b from-theme to-transparent/1 bg-clip-text text-transparent">
                  {stat.number}
                </div>
                <p className="text-gray-300">{stat.label}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default AboutUs;
